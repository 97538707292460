<template>
  <div class="grid lg:grid-cols-12 md:grid-cols-10 sm:grid-cols-8 gap-4" style="height: calc(100% - 110px);">
    <div class="col-span-4 box">
      <div class="flex justify-between pr-8">
        <i :class="`pi pi-angle-left my-auto cursor-pointer mx-2`" style="font-size: 1.4rem" @click="router.back()"></i>
        <div class="bg-grad w-11 h-11 rounded-full mx-auto flex justify-center">
          <!-- <UserIcon class="text-theme-22 my-auto" /> -->
          <FileTextIcon class="text-theme-22 my-auto" />
        </div>
      </div>
      <p class="text-center font-bold">{{ proceso.nombre }}</p>
      <!-- {{ carpetas }} -->
      <div v-for="(folder, index) in carpetas" :key="index" class="border-b">
          <div class="flex p-2 bg-item cursor-pointer relative">
              <i :class="`pi pi-angle-${folder.actived ? 'down':'right'} my-auto`" style="font-size: 1.4rem" @click.stop="desplegarDocumentos(folder)" />
              <i :class="`pi pi-folder my-auto mx-2`" style="font-size: 1rem"></i>
              <p v-if="editarFolder != folder.id" :class="`my-auto `">{{ folder.nombre }}</p>
              <InputText v-if="editarFolder == folder.id" v-model="folder.nombre" type="text" class="rounded-md w-full" @click.stop="" />
              <i v-if="editarFolder == folder.id" :class="`pi pi-save my-auto mx-2`" style="font-size: 1rem" @click.stop="actualizarCarpeta(folder)"></i>
              <i v-if="editarFolder == folder.id" :class="`pi pi-times my-auto ml-auto mx-2`" style="font-size: 1rem" @click.stop="editarFolder = null"></i>
          </div>
          <Toast />
          <div v-show="folder.actived" class="collapse-content pl-5">
              <!-- documentos -->
              <div v-for="(doc, index) in folder.documentos" :key="index" class="border-b">
                  <div class="flex p-2 bg-item cursor-pointer" @click="getArchivo(doc)">
                      <i :class="`pi pi-${ doc.tipo === 'pdf' || doc.tipo === 'txt' ? 'file-pdf text-red-400': 'file-excel text-green-400'} my-auto mx-2`" style="font-size: 1rem"></i>
                      <p :class="` my-auto `">{{ doc.cod }} {{ doc.nombre }}</p>
                  </div>
              </div>
              <p v-if="!folder.documentos.length">Sin Datos</p>
          </div>
      </div>
      <p v-if="!carpetas.length">Sin Datos</p>
    </div>
    <div class="col-span-8 box">
      <embed v-if="filePath" :src="`data:application/pdf;base64,${filePath}#zoom=80&bookmarks=1`" style="width:100%;height: 100%;">
    </div>
    <!-- BEGIN: nuevo doc -->
    <Dialog v-model:visible="displayAddDoc" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">
      <template v-slot:header>
            <div class="w-full">
                <div class="flex justify-center">
                  <FilePlusIcon class="block mr-2 h-5" />
                  <b>Agregar Documento</b>
                </div>
            </div>
        </template>
        <div class="mb-5">
            <label class="mb-2">Nombre del documento.value</label>
            <InputText v-model="newDocumento.nombre" type="text" class="rounded-md w-full" />
        </div>
        <div class="">
          <button class="w-full">
            <FilePond ref="pond"/>
          </button>
        </div>
        <template #footer>
            <Button label="Si" icon="pi pi-check" autofocus @click="crearDocumento()" />
            <Button label="No" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="displayPrograma = false" />
        </template>
    </Dialog>
    <!-- END: nuevo doc -->
  </div>
</template>
<script>

import { onMounted, ref, computed } from 'vue'
import CarpetasServices from './services/get.service'
import PutService from './services/put.service'
import PostService from './services/post.service'
import { useRoute, useRouter } from 'vue-router'
import FilePond from './components/filePound.vue'
import FilePondStore from './filepond.store'
import ModalSolicitudesService from '@/apps/pharmasan/calidad/views/mapa/services/modalSolicitudes.service'
// import axios from 'axios'
export default {
  name: 'CalidadCarpetas',
  components: {
    FilePond
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const _ModalSolictudesService = ref(new ModalSolicitudesService())
    const _Get = ref(new CarpetasServices())
    const _Post = ref(new PostService())
    const _Put = ref(new PutService())
    const adjuntos = ref([])
    const title = ref('')
    const filePath = ref('')
    const carpetas = ref([])
    const proceso = ref({})
    const addCarpeta = ref(false)
    const newCarpeta = ref('')
    const editarFolder = ref(0)
    const addDocumento = ref(false)
    const newDocumento = ref({})
    const displayAddDoc = ref(false)
    const adjunto = computed(
      () => FilePondStore.getters.adjunto
    )
    const getProceso = () => {
      _Get.value.getMapaProceso(route.params.id).then(({ data }) => {
        proceso.value = data
      }).catch(err => {
      console.log(err.message)
      })
    }
    const listarCarpetas = () => {
      _Get.value.getcarpetas({ padre: route.params.id }).then(({ data }) => {
        data.map(m => {
          m.documentos = []
          m.dropdown = false
        })
          carpetas.value = data
      }).catch(err => {
      console.log(err.message)
      })
    }
    const desplegarDocumentos = (event) => {
      _Get.value.getdocumentos({ padre: event.id }).then(async ({ data }) => {
        // console.log('get documentos----', data)
            const itemsTmp = []
            for (const row of data) {
              var archivo = await _Get.value.getArchivo(row.id, true).then(({ data }) => {
                    return data
                }).catch(err => {
                    console.log(err.message)
                })
              var arraynombre = row.nombre.split(' ')
              var datos = {
                cod: arraynombre.shift(),
                nombre: arraynombre.join(' '),
                tipo: archivo.tipo,
                id: row.id,
                posicion: row.posicion,
                version: archivo.version,
                createdAt: archivo.solicitud.createdAt.substring(0, 10),
                updatedAt: archivo.solicitud.updatedAt.substring(0, 10)
                // areaId: row.areaId
              }
                // console.log('lo que lista del archivo', datos)
                itemsTmp.push(datos)
            }
            // carpetaActived.value = carpetaActived.value === event.id ? 0 : event.id
            // if (itemsTmp.length) documentos.value = itemsTmp
            event.actived = event.actived ? 0 : true
            event.documentos = itemsTmp
        }).catch(err => {
        console.log(err.message)
        })
      }
    const actualizarCarpeta = (proId, folder) => {
        var { id, nombre } = folder
        _Put.value.putCarpeta({ id, nombre, procesoId: proId }).then(({ data }) => {
            editarFolder.value = 0
        }).catch(err => {
        console.log(err.message)
        })
    }
    const getArchivo = (file) => {
      var { id } = file
      _Get.value.getArchivoPath(id).then(({ data }) => {
          file.path = data
            viewPdf(file)
            // const res = axios.getUri({ url: data.path })
            // console.log('el rest descargar----', process.env.VUE_APP_MICROSERVICE_API_CALIDAD + res)
        }).catch(err => {
          console.log(err.message)
        })
    }
    const viewPdf = (file) => {
      var dataHtml = {
          path: file.path,
          tipo: 'txt',
          nombreArchivo: file.nombre,
          version: file.version,
          cod: file.cod,
          createdAt: file.createdAt,
          updatedAt: file.updatedAt
        }
        _ModalSolictudesService.value.getHtml(dataHtml).then(({ data }) => {
            if (file.tipo === 'pdf' || file.tipo === 'txt') {
                filePath.value = data.base
            } else {
              window.open(data, '_blank')
            }
        }).catch(err => {
          console.log(err.message)
        })
    }
    const modalAddDoc = (folder) => {
      displayAddDoc.value = true
      newDocumento.value.carpetaId = folder.id
      newDocumento.value.posicion = folder.documentos.length ? (Math.max(...folder.documentos.map(o => o.posicion)) + 1) : 1
      adjuntos.value.push({
        urlPath: adjunto.value.urlPath,
        fileName: title.value
      })
    }
    const crearDocumento = () => {
        _Post.value.crearDocumento(newDocumento.value).then(({ data }) => {
            desplegarDocumentos({ id: newDocumento.value.carpetaId })
            addDocumento.value = false
            newDocumento.value = {}
            displayAddDoc.value = false
        }).catch(err => {
        console.log(err.message)
        })
    }

    onMounted(() => {
      // var myEmbed = document.getElementById('myEmbed')
      // myEmbed.setAttribute('controls', false)
      getProceso()
      listarCarpetas()
    })
    return {
      filePath,
      router,
      carpetas,
      proceso,
      addCarpeta,
      newCarpeta,
      editarFolder,
      addDocumento,
      newDocumento,
      displayAddDoc,
      getArchivo,
      modalAddDoc,
      desplegarDocumentos,
      actualizarCarpeta,
      crearDocumento
    }
  }
}
</script>
<style lang="scss">
.bg-grad{
  background: transparent linear-gradient(1deg, #0080F9 0%, #00DCB1 100%) 0% 0% no-repeat padding-box;
}
.w-proceso{
  width: 30%;
}
</style>
